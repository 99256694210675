<template>
  <div class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <form @submit.prevent="submit" class="row purchase-ht4 no-print">
      <div class="col-12 pb-1 no-print">
        <div class="row">
          <div class="col-10 pl-0">
            <h4 class="content-title content-title-xs">Edit Sale</h4>
          </div>
          <div class="col-2 float-right text-right pr-0 pl-5">
            <select
              v-model="formData.branch"
              class="form-control right-select-drop"
              disabled
              @change="getDataByBranch(formData.branch)"
            >
              <option value disabled class="disabledColor">
                Select Branch
              </option>
              <option
                v-for="(branch, index) in dataSets.branches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-3 bg-theam-secondary mx-0 p-2 no-print">
        <div
          :class="
            dataSets.sales_setting.shipping_details == 1
              ? 'purchase-supplier-ht'
              : 'purchase-supplier-ht-change'
          "
        >
          <div>
            <span class="title-supplier">Customer</span>
            <!-- <button
              v-if="checkSinglePermission('create_customer')"
              type="button"
              class="float-right btn btn-primary btn-xs"
              @click="
                $store.dispatch('modalWithMode', {
                  id: 'newCustomer',
                  mode: 'create',
                })
              "
            >
              New Customer
            </button> -->
          </div>
          <div class="input-group py-2">
            <button
              type="button"
              class="btn-primary input-group-text"
              id="supplier_name"
            >
              <i class="fa fa-user"></i>
            </button>
            <input
              type="text"
              disabled
              class="form-control"
              placeholder="Guest Customer"
              v-model="formData.customerName"
              data-toggle="modal"
              data-target="#customerList"
              @click="focusSearch('customerSearch')"
              readonly
            />
          </div>
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Bill Date *</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input type="date" class="form-control" v-model="formData.date" />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Bill No</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                type="text"
                class="form-control"
                v-model="salesIdWithPrefix"
                placeholder="454667168721"
                disabled
              />
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Customer Due</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                v-model="customerDue"
                type="text"
                class="form-control"
                placeholder="0.00"
                disabled
              />
            </div>
          </div>
          <div class="row py-2" v-if="authData.general_setting.mng_system == 1">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Table No</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                class="form-control"
                v-model="formData.table_id"
                required
                @change="addFormDataToLocalStorage(formData)"
              >
                <option value disabled class="disabledColor" selected>
                  Select Table
                </option>
                <option
                  v-for="(table, index) in dataLists6.tables"
                  :key="index"
                  :value="table.id"
                >
                  {{ table.table }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class>
          <div class="row" v-if="dataSets.sales_setting">
            <div class="col-7 mr-0 pr-0">
              <button
                type="button"
                class="btn btn-xs btn-primary"
                @click="$store.dispatch('modalOpen', 'shippingDetils')"
                v-if="dataSets.sales_setting.shipping_details == 1"
              >
                Shipping Detail
              </button>
            </div>
            <div class="col-5 ml-0 pt-0 d-flex justify-content-end">
              <div
                class="from-group-check"
                v-if="dataSets.sales_setting.export == 1"
              >
                <input
                  class="group-check-box purchase-import-check"
                  type="checkbox"
                  v-model="formData.shipping_type"
                  value="0"
                  id="shipping_type"
                />
                <label
                  for="shipping_type"
                  class="page-text-import pd-t-10 ml-2 mb-0"
                  >Export</label
                >
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-5 mr-0 pr-1">
              <label class="page-text">Payment Method</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                class="form-control"
                v-model="formData.payment_method"
                @change="selectPaymentMethod"
              >
                <option value selected disabled>Select Payment Method</option>
                <option
                  v-for="(methods, index) in dataSets.paymentMethods"
                  :key="index"
                  :value="methods.id"
                >
                  {{ methods.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Bank</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                v-model="formData.bank"
                v-if="formData.paymentTitle == 'bank'"
                class="form-control"
                @change="selectBank()"
              >
                <option value disabled>Select Bank</option>
                <option
                  v-for="(bank, index) in itemsByBranch.banks"
                  :key="index"
                  :value="bank.id"
                >
                  {{ bank.bank }}
                </option>
              </select>
              <input class="form-control" type="text" v-else disabled />
            </div>
          </div>
          <div class="row">
            <!-- Ref. is replaced with txn. id -->
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Ref.</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                type="text"
                class="form-control"
                v-model="formData.txn_id"
                placeholder="Payment Ref."
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 mx-0 pl-2 pr-0 purchase-ht2 no-print">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-6 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    type="button"
                    class="btn-primary input-group-text"
                    id="product"
                  >
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="items.name"
                  placeholder="Items"
                  data-toggle="modal"
                  data-target="#salesItemList"
                  @click="openSalesitemComponent()"
                  readonly
                />
                <button
                  v-if="checkSinglePermission('create_item')"
                  class="float-left btn btn-primary btn-xs ml-2 pr-0 mt-0 mr-2"
                  type="button"
                  @click="
                    $store.dispatch('modalWithMode', {
                      id: 'newItem',
                      mode: 'create',
                    })
                  "
                >
                  New Item
                </button>
              </div>
            </div>

            <div
              class="col-4 mr-0 ml-0 pl-1"
              v-if="dataSets.item_setting.barcode == 1"
            >
              <div class="page-text">Barcode</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="barcode">
                    <i class="fas fa-barcode"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  autofocus
                  placeholder="Barcode"
                  @keyup.prevent="getDataByBarcode"
                  v-model.trim="items.barcode"
                  ref="barcode"
                />
              </div>
            </div>
            <div class="col-2 pl-0" v-if="dataSets.item_setting.expiry == 1">
              <div v-if="itemStockable">
                <div class="page-text">Expiry Date</div>
                <div class="input-group">
                  <input
                    type="date"
                    class="form-control"
                    :class="{ expiry_date: itemExpired }"
                    placeholder
                    v-model="items.expiry_date"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-2 mr-0 pr-0 pt-2 zi-0"
              v-if="itemStockable && !authData.general_setting.org_type == 0"
            >
              <div>
                <div class="page-text">Stock *</div>
                <div class="input-group">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    placeholder="0"
                    v-model="items.available"
                    disabled
                  />
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      style="background: #8db8ee; color: black"
                      >{{ items.unit_type }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">Quantity *</div>
              <div class="input-group">
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  placeholder="0"
                  min="1"
                  v-model.trim="items.quantity"
                  @keyup="calculateDiscount()"
                  @change="calculateDiscount()"
                />
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-2 mr-0 pt-2">
              <div class="page-text">Sales Rate *</div>
              <div class="input-group">
                <input
                  type="number"
                  step="any"
                  min="0"
                  class="form-control"
                  v-model="items.sales_rate"
                  placeholder="0.00"
                  @keyup="calculateDiscount()"
                  @change="calculateDiscount()"
                />
              </div>
            </div>
            <div class="col-2 pt-2 pr-0 pl-0">
              <div class="page-text">Sales Discount</div>
              <div class="input-group">
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  placeholder="0"
                  min="0"
                  v-model="items.sales_discount_percentage"
                  @keyup="calculateDiscount()"
                  @change="calculateDiscount()"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" style="min-width: 0px"
                    >%</span
                  >
                </div>
                <input
                  type="number"
                  step="any"
                  min="0"
                  class="form-control wd-20pimp"
                  placeholder="0.00"
                  v-model="items.sales_discount_amount"
                  @keyup="changeSalesRate()"
                  @change="changeSalesRate()"
                  @keydown.tab.exact="
                    options.edit == true ? updateItem() : addItem()
                  "
                />
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-1 mr-0 pr-0">
              <label class="page-text">Total</label>
            </div>
            <div class="col-2 ml-0 pl-0">
              <input
                type="number"
                step="any"
                class="form-control"
                v-model="items.total"
                disabled
              />
            </div>
            <div class="col-9 d-flex justify-content-end">
              <div v-if="options.edit">
                <div
                  class="btn btn-success btn-xs"
                  @click="updateItem"
                  type="button"
                >
                  Update Item
                </div>
                <div
                  class="btn btn-danger btn-xs ml-2"
                  @click="closeUpdate()"
                  type="button"
                >
                  Close
                </div>
              </div>
              <div
                v-else
                class="btn btn-success btn-xs"
                @click.exact="addItem"
                type="button"
              >
                Add Item
              </div>
            </div>
          </div>
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th scope class="wd-5p table-start-item">S.N.</th>
                <th scope class="wd-30p">Particular</th>
                <th scope class="wd-10p">Available Qty</th>
                <th scope class="wd-15p">Qty</th>
                <th scope class="wd-10p">Rate</th>
                <th scope class="wd-10p">Discount</th>
                <th scope class="wd-10p">Total</th>
                <th scope class="wd-10p text-right table-end-item">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>
                  <span class="text-center" v-if="item.item_type == 'Non-Stockable'"
                    >-</span
                  >
                  <span v-else
                    >{{ item.available }} {{ item.item.unit_type.title }}</span
                  >
                </td>
                <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                <td>{{ parseDigit(item.sales_rate) }}</td>
                <td>{{ parseDigit(item.sales_discount_amount) }}</td>
                <td>{{ parseDigit(item.total) }}</td>
                <td class="text-right table-end-item">
                  <a
                    href="javascript:;"
                    class="mr-2"
                    @click="editItem(index)"
                    title="Edit"
                  >
                    <i class="fa fa-edit"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="mr-0"
                    title="Remove"
                    @click="removeItem(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 bg-theam-secondary p-2 mt-2 no-print">
        <div class="row">
          <div class="col-3 pr-0">
            <div class="page-text">Note</div>
            <textarea
              name="note"
              class="form-control page-note"
              v-model="formData.note"
            ></textarea>
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    v-model="formData.total"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    step="any"
                    type="number"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">
                  VAT-able Amount
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="
                      Please adjust vatable amount only after adding the extra fields.If you add/edit extra field after editing the vatable amount,vatable amount will be reset and will be recalculated.
                    "
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    v-model="formData.vatable_amount"
                    class="form-control"
                    placeholder="0.00"
                    min="0"
                    step="any"
                    type="number"
                    @keyup="calculateCustomAmount()"
                    @change="calculateCustomAmount()"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">VAT Amount</div>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="0.00"
                    v-model="formData.vat_amount"
                    step="3"
                    disabled
                  />
                </div>
              </div>
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Paid Amount</div>
                <div class="input-group">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    min="0"
                    v-model="formData.paid_amount"
                    placeholder="0.00"
                    @keyup="calculateDue()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 m-0">
                <div class="row">
                  <div
                    v-for="(
                      extra_field, index
                    ) in dataLists6.sales_extra_fields"
                    :key="index"
                    class="col-2 pt-2 mr-0 pr-0"
                  >
                    <div class="page-text">
                      {{ extra_field.title }} ({{
                        extra_field.type == 0 ? "+" : "-"
                      }})
                      <i
                        class="fa fa-info-circle primary"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        :title="
                          extra_field.vat == 1 ? 'Vatable' : 'Non Vatable'
                        "
                      ></i>
                    </div>
                    <div class="input-group">
                      <input
                        :id="`ext-${extra_field.slug}`"
                        :placeholder="extra_field.title"
                        class="form-control"
                        step="any"
                        min="0"
                        type="number"
                        v-model="formData.reactiveExtraFields[index]"
                        @keyup="extraFields({ ...extra_field })"
                        @change="extraFields({ ...extra_field })"
                      />
                    </div>
                  </div>
                  <div class="col-3 mr-0 pt-2 pr-0">
                    <div class="page-text">Bill Amount</div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="formData.bill_amount"
                        placeholder="0.00"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-3 mr-0 pt-2 pr-0">
                    <div class="page-text">Due Amount</div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="formData.due_amount"
                        placeholder="0.00"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <!-- </div>
                <div class="col-6 m-0">-->
                <!-- <div class="row"> -->
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mr-0">
              <button
                class="col-7 btn btn-success btn-sm btn-min-wd my-2"
                type="button"
                @click.prevent="submitData()"
                :disabled="submitting"
              >
                {{ submitting ? "Submitting...." : "Submit" }}
              </button>
              <!-- <button
                class="col-7 btn btn-warning btn-sm btn-min-wd my-2"
                type="button"
                @click.prevent="reset('sales')"
              >
                Reset
              </button> -->
              <button
                v-if="checkSinglePermission('view_sale')"
                class="col-7 btn btn-danger btn-sm btn-min-wd"
                type="button"
                @click.prevent="cancelSale()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!--    modals-->
    <div class="row">
      <!--      customer lists-->
      <div class="col-12">
        <div
          class="modal fade"
          id="customerList"
          role="dialog"
          aria-hidden="true"
        >
          <div
            class="modal-dialog listing-popup-place modal_ac_head"
            role="document"
          >
            <div class="modal-content search-table-container">
              <!-- search field -->
              <div class="form-group has-search mb-0">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  v-model="customerSearched"
                  @input="searchCustomer"
                  ref="customerSearch"
                />
              </div>
              <table class="table table1" id="tableData">
                <thead>
                  <tr>
                    <th scope class="wd-5p table-start-item">S.N.</th>
                    <th scope class="wd-15p">Customer Id</th>
                    <th scope class="wd-30p">Customer Name</th>
                    <th scope class="wd-30p">Address</th>
                    <th scope class="wd-20p">Phone No</th>
                  </tr>
                </thead>
                <tbody v-if="!customerLoading && dataSets.customers.length > 0">
                  <tr
                    v-for="(customer, index) in dataSets.customers"
                    :key="index"
                    @click="selectCustomer(customer.id)"
                    data-dismiss="modal"
                  >
                    <th scope="row" class="table-start-item">{{ ++index }}</th>
                    <td>{{ customer.customer_id }}</td>
                    <td>{{ customer.name }}</td>
                    <td>{{ customer.address }}</td>
                    <td>{{ customer.phone }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="!customerLoading && dataSets.customers == 0">
                  <tr>
                    <td colspan="11" style="text-align: center">
                      No records found.
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="customerLoading">
                  <tr>
                    <td colspan="11" style="text-align: center">
                      Loading.....
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="error">
                  Error Fetching data from the server.
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--      items list-->

      <!-- Modal code should be here -->

      <!--      shipping details-->
      <div class="col-12">
        <div
          class="modal fade modal_cust show"
          v-if="modalId == 'shippingDetils'"
          tabindex="-1"
          role="dialog"
          style="display: block"
          aria-hidden="true"
        >
          <div
            class="
              modal-dialog modal-dialog-centered modal_ac_head
              single-column-popup
            "
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Add Shipping Detail
                </h5>
                <a
                  class="close"
                  @click="$store.dispatch('modalClose', 'supplierList')"
                >
                  <span aria-hidden="true">×</span>
                </a>
              </div>
              <div class="bg-theam-secondary">
                <div class="modal-body row">
                  <div class="col-12 pd-r-7-5">
                    <div class="group">
                      <label class="group-label">Details</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-5 mr-0 pr-0">Driver</div>
                          <div class="col-7 ml-0 pl-0">
                            <input
                              v-model="formData.driver"
                              class="form-control"
                              placeholder="Driver Name"
                              @blur="addFormDataToLocalStorage(formData)"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-5 mr-0 pr-0">Vehicle</div>
                          <div class="col-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.vehicle"
                              placeholder="vehicle number"
                              class="form-control"
                              @blur="addFormDataToLocalStorage(formData)"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-5 mr-0 pr-0">Contact</div>
                          <div class="col-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.contact"
                              placeholder="9840000000"
                              class="form-control"
                              @blur="addFormDataToLocalStorage(formData)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-2">
                      <button
                        class="btn btn-primary btn-sm mt-1 mb-0"
                        @click="$store.dispatch('modalClose', 'supplierList')"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewCustomer />
    <NewItem :show-stock-required="false" @parent-event="newItem()" />
    <SalesItemComponent
      @clicked="componentItemClicked"
      ref="salesItemComponent"
    ></SalesItemComponent>
    <SalesSlip @printSlip="printSlip" />
  </div>
</template>
<script>
import SalesSlip from "./SalesSlip";
import NewCustomer from "../Customer/NewCustomer";
import { mapGetters } from "vuex";
import NewItem from "../Item/NewItem";
import SalesItemComponent from "./Component/SalesItemComponent";
import Services from "./Services/Service";
import _ from "lodash";

export default {
  components: {
    SalesSlip,
    NewItem,
    NewCustomer,
    SalesItemComponent,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      dataSets: {
        customers: [],
        items: [],
        paymentMethods: [],
        banks: [],
        branches: [],
        sales_setting: [],
        item_setting: [],
      },
      old_sales_data : "",
      loadData:true,
      guestCustomerDue: 0,
      customerDue: 0,
      submitting: false,
      loading: true,
      error: false,
      errorMessage: "",
      itemExpired: false,
      customerSearched: "",
      branchDisabled: false,
      newVatable: 0,
      salesPrefix: "",
      salesIdWithPrefix: "",
      itemStockable: true,
      salesSuccess: false,
      salesBillId: "",
      customerLoading: false,
      cashPaymentMethodId: "",
      formData: {
        reactiveExtraFields: [],
        paymentTitle: "",
        customerName: "",
        branch: "",
        customer_id: "",
        date: null,
        bill_no: null,
        shipping_type: 0,
        driver: "",
        vehicle: "",
        contact: "",
        payment_method: "",
        prefix: "",
        bank: null,
        txn_id: null,
        vatable_amount: null,
        vat_amount: null,
        bill_amount: null,
        extra_fields: [],
        paid_amount: null,
        due_amount: null,
        sales_amount: null,
        total: null,
        note: null,
        table_id: "",
        items: [],
      },
      items: {
        unit_type: null,
        vat_applicable: null,
        item_id: null,
        name: null,
        available: null,
        quantity: null,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        total: null,
        barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "processing",
      "dataId",
      "dataLists6",
      "dataLists7",
      "dataLists9",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
      "today",
      "authData",
    ]),
    ...mapGetters("sales", [
      "salesItem",
      "itemsByBranch",
      "itemSearchLoading",
      "branchId",
      "itemGroups",
      "slipSettings",
    ]),
    ...mapGetters("customers", ["customerLists"]),
    ...mapGetters("items", ["itemLists"]),
  },
  mounted() {
    this.formData.date = this.today;
    this.getData();
    //this.getEditableData();

    let data = JSON.parse(localStorage.getItem("salesEditFormData"));
    // checking if data exists in localstorage
    if (data) {
      this.formData = data;
      // getting items of selected branch
      if (this.formData.branch) {
        this.getDataByBranch(this.formData.branch);
        this.branchDisabled = true;
        //this.calculateTotal();
      }
    }
  },
  methods: {
    //when new item in created
    newItem() {
      this.$store.commit("sales/setPushItemsByBranchItems", this.itemLists);
    },
    getEditableData() {
      this.loadData = true;
      //todos - customer block,branch block,unit type,
      Services.Sales.getEditDataForSales(this.$route.params.id)
        .then((res) => {
          var value = res.data.data.sales;
          this.formdata = value;
          this.old_sales_data = value;
          // this.selectCustomer(value.customer_id);
          this.formData.customerName = value.customer.name;
          this.formData.customer_id = value.customer.id;
          this.customerDue = value.customer.closing_due;
          this.salesPrefix = value.prefix;
          this.formData.bill_no = value.bill_no;
          this.salesIdWithPrefix = value.prefix + value.bill_no;
          this.formData.date = value.date;
          this.formData.branch = value.branch;
          this.getDataByBranch(this.formData.branch);
          this.formData.items = value.items;
          this.formData.shipping_type = value.shipping_type;
          this.formData.note = value.note;
          this.formData.sales_amount = value.sales_amount;
          this.formData.paid_amount = value.paid_amount;
          this.formData.total = this.parseDigit(value.sales_amount);
          this.formData.vat_amount = value.vat_amount;
          this.formData.vatable_amount = value.vatable_amount;
          this.formData.bill_amount = value.bill_amount;
          this.formData.due_amount = value.due_amount;
          this.formData.extra_fields = value.extra_fields;
          this.formData.driver = value.driver;
          this.formData.vehicle = value.vehicle;
          this.formData.contact = value.contact;
          this.formData.payment_method = value.payment_method;
          this.selectPaymentMethod();
          this.formData.txn_id = value.txn_id;
          if (value.bank) {
            this.formData.paymentTitle = "bank";
            this.formData.bank = value.bank;
          }
          value.extra_fields.forEach((se) => {
            this.extraExist = false;
            this.formData.extra_fields.forEach((el) => {
              if (el.slug == se.slug) {
                this.formData.reactiveExtraFields.push(el.amount);
                this.extraExist = true;
              }
            });
            if (this.extraExist == false) {
              this.formData.reactiveExtraFields.push("");
            }
          });           
        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>{
          this.loadData = false;
        });
    },
    getData() {
      Services.Sales.getDataForSalesCreation()
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("setDataLists6", value);
          this.loading = false;
          this.dataSets.customers = value.customers;
          this.dataSets.branches = value.branches;
          this.dataSets.sales_setting = value.sales_setting;
          this.dataSets.item_setting = value.item_setting;
          this.$store.commit("sales/setItemGroups", value.item_groups);
          this.customerDue = value.guestCustomerDue.closing_due;
          this.guestCustomerDue = value.guestCustomerDue.closing_due;
          // checking if there is only one branch
          if (this.dataSets.branches.length == 1) {
            this.branchDisabled = true;
            this.formData.branch = value.branches[0]["id"];
            this.getDataByBranch(this.formData.branch);
          }
          this.salesPrefix = value.sales_setting.prefix ?? "";

          let data = JSON.parse(localStorage.getItem("salesEditFormData"));
          if (data) {
            this.formData.note = data.note;
          } else {
            this.formData.note = value.sales_setting.description;
          }

          this.salesIdWithPrefix = this.salesPrefix + 0;
          this.formData.prefix = this.salesPrefix;
          //if only barcode is enabled from item setting
          if (value.item_setting.barcode == 1) {
            setTimeout(() => {
              this.$refs.barcode.focus();
            }, 100);
          }
          this.getEditableData();
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        });
    },
    getDataByBranch(branchId) {
      this.items = {};
      this.formData.paymentTitle = "";
      this.formData.bank = "";
      this.itemsByBranch.items = [];
      this.itemsByBranch.branches = [];
      this.itemsByBranch.orderId = "";
      this.itemsByBranch.paymentMethods = [];
      this.$store.commit("sales/setItemSearchLoading", true);
      // this.$store.commit('sales/setItemsByBranch', []);
      this.itemExpired = false;
      localStorage.removeItem("salesEditPaymentMethod");
      Services.Sales.getPurchaseItemsByBranch(branchId)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("sales/setItemsByBranch", value);
          this.$store.commit("sales/setBranchId", branchId);
          this.dataSets.items = value.items;
          this.dataSets.paymentMethods = value.paymentMethods;
          let localData = JSON.parse(
            localStorage.getItem("salesEditPaymentMethod")
          );
          if (!localData) {
            if (this.dataSets.paymentMethods.length > 0) {
              this.dataSets.paymentMethods.forEach((payment) => {
                if (payment.title.toLowerCase() == "cash") {
                  this.formData.payment_method = payment.id;
                  this.cashPaymentMethodId = payment.id;
                  this.formData.paymentTitle = "";
                }
              });
            } else {
              this.formData.payment_method = "";
              this.formData.paymentTitle = "";
            }
          } else {
            if (localData.payment_method) {
              this.formData.payment_method = localData.payment_method;
              this.formData.paymentTitle = localData.paymentTitle;
              this.formData.bank = localData.bank;
            }
          }
          localStorage.setItem(
            "salesEditPaymentMethod",
            this.formData.payment_method
          );
        })
        .catch((error) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              
              this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.$store.commit("sales/setItemSearchLoading", false);
        });
      this.formData.payment_method = 1;
    },

    // Search Customer through ajax request
    searchCustomer: _.debounce(function () {
      this.customerLoading = true;
      let keyword = this.customerSearched;
      keyword = keyword ? keyword : "all";
      Services.Sales.getCustomersBySearchKeyword(keyword)
        .then((response) => {
          let value = response.data.data;
          this.dataSets.customers = value;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.customerLoading = false;
        });
    }, 600),

    openSalesitemComponent() {
      if (this.formData.branch) {
        this.$store.dispatch("modalWithMode", {
          id: "salesItemList",
          mode: "create",
        });
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.salesItemComponent.$refs.itemSearch.focus();
          });
        }, 600);
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    // receiving data from child component i.e SalesItemComponent
    componentItemClicked(details) {
      this.items = [];
      if (details["branch"] == null && details["purchase_id"] == null) {
        details["available"] = 1;
        details["quantity"] = 1;
        this.itemStockable = false;
      } else {
        this.itemStockable = true;
      }
      this.items = { ...details };
      this.items.purchase_id = details.purchase_id;
      this.items.item_id = details.item.id;
      this.items.name = details.item.name;
      this.items.unit_type = details.item.unit_type.title;
      this.items.vat_applicable = details.item.vat_applicable;
      this.items.quantity = 1;
      // this.formData.date = this.today;
      if (details.expiry_date) {
        let curretnDateInMiliSecond = new Date().getTime();
        let itemExptime = new Date(this.items.expiry_date);
        let itemExpTimeinMiliSecond = new Date(itemExptime).getTime();

        if (curretnDateInMiliSecond > itemExpTimeinMiliSecond) {
          this.itemExpired = true;
        } else {
          this.itemExpired = false;
        }
      } else {
        this.itemExpired = false;
      }

      this.calculateDiscount();
    },
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },

    selectCustomer(id) {
      let details = this.dataSets.customers.find(function (data) {
        return data.id == id;
      });
      this.formData.customerName = details.name;
      this.formData.customer_id = details.id;
      this.customerDue = details.closing_due;
      this.addFormDataToLocalStorage(this.formData);
    },

    selectPaymentMethod() {
      let value = this.formData.payment_method;
      this.itemsByBranch.paymentMethods.forEach((element) => {
        if (element.id == value) {
          if (element.title.toLowerCase() == "bank") {
            this.formData.paymentTitle = "bank";
          } else {
            this.formData.paymentTitle = "";
            this.formData.bank = "";
          }
        }
      });
      //
      localStorage.setItem("salesEditPaymentMethod", this.formData.payment_method);
      // updating the localstorage fomrData;
      this.addFormDataToLocalStorage(this.formData);
    },
    selectBank() {
      this.addFormDataToLocalStorage(this.formData);
    },

    //################ Barcode scan ##################
    getDataByBarcode: _.debounce(function () {
      if (this.items.barcode) {
        let branchId = this.formData.branch;
        if (branchId) {
          Services.Sales.getDataByBarcode(this.items.barcode, branchId)
            .then((response) => {
              let value = response.data.data;
              if (value) {
                this.items = { ...value };
                this.items.item_id = value.item.id;
                this.items.name = value.item.name;
                this.items.quantity = 1;
              } else {
                this.items = {};
                this.setNotification("No product Found");
              }
            })
            .catch((error) => {
              this.items = {};
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        } else {
          this.setNotification("Please select branch first.");
        }
      }
    }, 300),

    cancelSale() {
      this.$router.push({ name: "list-sales" });
    },
    changeSalesRate() {
      let rate = this.items.sales_rate;
      let saleDiscountAmt = parseFloat(this.items.sales_discount_amount);

      if (saleDiscountAmt == 0 || isNaN(saleDiscountAmt)) {
        this.items.sales_discount_percentage = 0;
        var salesDiscountPer = 0;
      } else {
        salesDiscountPer = this.parseDigit(
          (saleDiscountAmt / parseFloat(rate * this.items.quantity)) * 100
        );
        this.items.sales_discount_percentage =
          this.parseDigit(salesDiscountPer);
      }

      this.calculateItemTotal();
    },
    calculateDiscount() {
      // condition to determine whether the item is stockable or not
      if (!this.items.quantity || this.items.quantity == 0) {
        this.items.total = 0;
      }
      if (
        typeof this.items.purchase_id !== "undefined" &&
        this.items.purchase_id != null
      ) {
        if (this.items.quantity > parseFloat(this.items.available)) {
          this.items.quantity = this.items.available;
        }
      }
      let itemQuantity = this.items.quantity;
      let saleRate = this.items.sales_rate;
      let saleDiscountPer = this.items.sales_discount_percentage;
      if (itemQuantity > 0 && saleDiscountPer >= 0) {
        this.items.sales_discount_amount = this.parseDigit(
          (saleDiscountPer * (saleRate * itemQuantity)) / 100
        );
        this.calculateItemTotal();
      }
    },

    calculateTotal() {
    if(this.loadData == false){
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.due_amount = 0;
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? this.parseDigit(this.formData.paid_amount || 0)
          : 0;
      var itemVatable = false;

      this.formData.items.forEach((item) => {
        this.formData.total = this.parseDigit(
          this.formData.total + parseFloat(item.total)
        );
        if (this.formData.shipping_type == 0 && item.vat_applicable == 1) {
          itemVatable = true;
          this.formData.vatable_amount = this.parseDigit(
            parseFloat(this.formData.vatable_amount || 0) +
              parseFloat(item.total)
          );
        }
      });
      //if the items added on list is vatable .. thet vatable_amount will be reset on every item added
      if (itemVatable) {
        this.formData.vatable_amount = this.parseDigit(
          parseFloat(this.formData.vatable_amount || 0) +
            parseFloat(this.newVatable || 0)
        );
      }
      this.calculateCustomAmount();
      }
    },

    calculateVatableAmtExtraFieldCase() {
      // if (this.formData.vatable_amount > 0) {
      this.newVatable = 0;
      this.formData.extra_fields.forEach((extra, index) => {
        if (index == 0) {
          this.newVatable = 0;
        }
        if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 1) {
          this.newVatable = this.newVatable - parseFloat(extra.amount || 0);
        } else if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 0) {
          this.newVatable = this.newVatable + parseFloat(extra.amount || 0);
        } else {
          this.newVatable = this.newVatable + 0;
        }
      });
      // } else {
      //   this.newVatable = 0;
      // }
      this.calculateTotal();
    },

    calculateCustomAmount() {
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.formData.sales_amount = parseFloat(this.formData.total || 0).toFixed(
        2
      );
      this.formData.bill_amount = this.parseDigit(
        parseFloat(this.formData.total) +
          parseFloat(this.formData.vat_amount || 0)
      );
      this.formData.extra_fields.forEach((extra) => {
        if (parseInt(extra.type) == 0) {
          this.formData.bill_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) +
              parseFloat(extra.amount || 0)
          );
          this.formData.sales_amount = this.parseDigit(
            parseFloat(this.formData.sales_amount || 0) +
              parseFloat(extra.amount || 0)
          );
        } else {
          this.formData.bill_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) -
              parseFloat(extra.amount || 0)
          );
          this.formData.sales_amount = this.parseDigit(
            parseFloat(this.formData.sales_amount || 0) -
              parseFloat(extra.amount || 0)
          );
        }
      });
      this.formData.paid_amount = this.parseDigit(
        this.formData.bill_amount || 0
      );
      this.calculateDue();
    },

    calculateDue() {
      if (
        parseFloat(this.formData.paid_amount || 0) >
        this.parseDigit(this.formData.bill_amount)
      ) {
        this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
      }
      if (!this.formData.paid_amount) {
        this.formData.due_amount = this.parseDigit(this.formData.bill_amount);
      } else {
        this.formData.due_amount = this.parseDigit(
          this.parseDigit(this.formData.bill_amount || 0) -
            parseFloat(this.formData.paid_amount || 0)
        );
        this.formData.due_amount =
          this.formData.due_amount > 0
            ? this.parseDigit(this.formData.due_amount || 0)
            : 0;
      }
    },

    calculateItemTotal() {
      if (this.items.sales_discount_amount > 0) {
        this.items.total = this.parseDigit(
          this.items.quantity * parseFloat(this.items.sales_rate || 0) -
            parseFloat(this.items.sales_discount_amount || 0)
        );
      } else {
        this.items.total = this.parseDigit(
          this.items.quantity * parseFloat(this.items.sales_rate || 0)
        );
      }
      this.items.total = this.parseDigit(this.items.total);
    },
    addItem() {
      if (this.itemValidation()) {
        if (this.formData.items.length > 0) {
          let item_id = this.items.item_unique_id;
          let filteredItem = this.formData.items.find(function (data) {
            return data.item_unique_id == item_id;
          });
          if (filteredItem == undefined) {
            if (
              this.items["branch"] == null &&
              this.items["purchase_id"] == null
            ) {
              this.items["item_type"] = "Non-Stockable";
            } else {
              this.items["item_type"] = "Stockable";
            }
            this.formData.items.push({ ...this.items });
            // to disable the branch selection
            this.branchDisabled = true;
            // to change the color of date if expired
            this.itemExpired = false;
            // to clear the item fields
            this.clearItem();
            this.itemStockable = true;
            this.addFormDataToLocalStorage(this.formData);
          } else {
            let err = `${this.items.name} already in sales list.`;
            this.setNotification(err);
            this.clearItem();
          }
        } else {
          if (
            this.items["branch"] == null &&
            this.items["purchase_id"] == null
          ) {
            this.items["item_type"] = "Non-Stockable";
          } else {
            this.items["item_type"] = "Stockable";
          }
          this.formData.items.push({ ...this.items });
          this.addFormDataToLocalStorage(this.formData);
          this.branchDisabled = true;
          this.itemExpired = false;
          this.itemStockable = true;
          this.clearItem();
        }
        this.calculateTotal();
      }
    },
    itemValidation() {
      if (!this.items.item_id) {
        this.setNotification("Please select Item first.");
        return false;
      }
      if (this.items.quantity <= 0) {
        this.setNotification("Quantity must be greater than 0.");
        return false;
      }
      if (this.items.sales_rate < 0) {
        this.setNotification("Sales Rate cannot be less than 0.");
        return false;
      }
      if (this.items.total < 0) {
        this.setNotification("Total cannot be less than 0.");
        return false;
      }
      return true;
    },
    addFormDataToLocalStorage(value) {
      localStorage.setItem("saleEditFormData", JSON.stringify(value));
      this.$store.commit("sales/setBranchId", this.formData.branch);
      // this.calculateTotal();
      // this.calculateCustomAmount();
    },
    removeItem(index) {
      this.$swal({
        title: "Do you really want to remove this item ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.items.splice(index - 1, 1);
          if (this.formData.items.length == 0) {
            this.branchDisabled = false;
          }
          this.calculateTotal();
          this.addFormDataToLocalStorage(this.formData);
        }
      });
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      this.items.unit_type = this.items.item.unit_type.title;
      if (this.items["validity"] == "Expired") {
        this.itemExpired = true;
      } else {
        this.itemExpired = false;
      }
      if (this.items["branch"] == null && this.items["purchase_id"] == null) {
        this.itemStockable = false;
      } else {
        this.itemStockable = true;
      }
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
     if (this.itemValidation()) {
        let dataAdded = false;
        let currentAddedItems = this.formData.items;
        currentAddedItems.forEach((el, index) => {
          if (el.item_unique_id == this.items.item_unique_id) {
            dataAdded = true;
            this.formData.items[index] = { ...this.items };
          }
        });
        if (dataAdded == false) {
          this.formData.items.push({ ...this.items });
        }
        this.calculateTotal();
        this.items = [];
        this.branchDisabled = true;
        this.options.edit = false;
        this.itemStockable = true;
      }
    },
    closeUpdate() {
      this.options.edit = false;
      this.items = [];
      this.itemStockable = true;
      this.itemExpired = false;
    },
    submitData() {
      if (this.formValidation()) {
        this.storeItem();
      }
    },

    storeItem() {
      this.submitting = true;
      Services.Sales.updateSalesData(this.$route.params.id,this.formData)
        .then((res) => {
          if (
            res.status == 200 &&
            res.data.success_message == "Sales successfully."
          ) {
            this.guestCustomerDue = res.data.data.customer.closing_due;
            this.$store.commit(
              "sales/setItemsByBranchItems",
              res.data.data.items
            );
            this.$store.commit(
              "sales/setItemsByBranchSalesId",
              res.data.data.sale.bill_no
            );
            this.formData.bill_no = res.data.data.sale.bill_no;
            this.reset();
            this.customerDue = res.data.data.customer.closing_due;
            //making array because salesSlip always accepts an array of data
            let saleArray = [];
            saleArray.push(res.data.data.sale);
            this.$store.commit("sales/setDataSets", saleArray);

            // providing the organization and sales setting to sales slip component
            this.$store.commit("sales/setSlipSettings", res.data.data);
            //poping up the SalesSlip modal
            this.$store.commit("setDataId", res.data.data.sale.id);

            this.$store
              .dispatch("modalWithMode", { id: "salesSlip", mode: "read" })
              .then(() => {
                this.printSlip(res.data.data.sale.id);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          //validation error
          if (error.response.status == 422) {
            let err = Object.values(error.response.data.errors).flat();
            this.setNotification(err);
          } else {
            let err = error.response.data.error_message;
            this.setNotification(err);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    formValidation() {
      if (this.formData.items.length == 0) {
        this.setNotification("Please add items first.");
        return false;
      }
      if (this.formData.paid_amount < 0) {
        this.setNotification("Paid amount cannot be less than 0.");
        return false;
      }
      if (this.formData.paymentTitle == "bank") {
        if (!this.formData.bank) {
          this.setNotification("Please select the bank.");
          return false;
        }
      }
      return true;
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },

    printSlip(id) {
      //once the modal is opened popup print window
      window.print();

      window.onafterprint = this.afterPrint(id);
    },
    afterPrint(salesId) {
      Services.Sales.updatePrintBillCount({ "sales-id": salesId })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearItem() {
      this.items.vat_applicable = null;
      this.items.item_id = null;
      this.items.name = null;
      this.items.available = 0;
      this.items.quantity = 0;
      this.items.sales_rate = 0;
      this.items.sales_discount_percentage = 0;
      this.items.sales_discount_amount = 0;
      this.items.total = 0;
      this.items.barcode = null;
      this.items.expiry_date = null;
      this.items.unit_type = null;
    },
    extraFields(details) {
      let index = this.formData.extra_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields[index].amount = amount;
        } else {
          this.formData.extra_fields[index].amount = 0;
        }
      } else {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields.push({
            slug: details.slug,
            type: details.type,
            vat: details.vat,
            amount: amount,
          });
        }
      }
      this.calculateVatableAmtExtraFieldCase();
      // adding data to localStorage
      this.addFormDataToLocalStorage(this.formData);
    },
    reset(type = null) {
      this.formData.customer_id = "";
      this.formData.shipping_type = 0;
      this.formData.driver = "";
      this.formData.vehicle = "";
      this.formData.contact = "";
      this.customerDue = this.guestCustomerDue;
      this.formData.txn_id = null;
      this.formData.vatable_amount = null;
      this.formData.vat_amount = null;
      this.formData.bill_amount = null;
      this.formData.extra_fields = [];
      this.formData.paid_amount = null;
      this.formData.due_amount = null;
      this.formData.total = null;
      this.formData.items = [];
      this.items.unit_type = null;
      this.items.unit_typevat_applicable = null;
      this.items.unit_typeitem_id = null;
      this.items.unit_typename = null;
      this.items.unit_typeavailable = null;
      this.items.unit_typequantity = 0;
      this.items.unit_typesales_rate = 0;
      this.items.unit_typesales_discount_percentage = 0;
      this.items.unit_typesales_discount_amount = 0;
      this.items.unit_typetotal = 0;
      this.items.unit_typebarcode = null;
      this.items.unit_typeexpiry_date = null;
      this.dataSets.items = [];
      this.formData.extra_fields = [];
      this.formData.reactiveExtraFields = [];
      this.formData.table_id = "";
      this.formData.customerName = "";
      this.formData.searchCustomer = "";
      this.formData.paymentTitle = "";
      this.formData.bank = "";
      // single and multiple branch case
      if (this.dataSets.branches.length == 1) {
        this.branchDisabled = true;
        if (type != "sales") {
          this.itemsByBranch.salesId++;
          this.formData.bill_no = this.itemsByBranch.salesId;
          this.salesIdWithPrefix = this.salesPrefix + this.formData.bill_no;
        }
      } else {
        this.branchDisabled = false;
        if (type != "sales") {
          this.itemsByBranch.salesId++;

          this.formData.bill_no = this.itemsByBranch.salesId;
          this.salesIdWithPrefix = this.salesPrefix + this.formData.bill_no;
        }
      }
      this.formData.payment_method = this.cashPaymentMethodId;
      this.formData.note = this.dataSets.sales_setting.description;
      if (this.dataSets.item_setting.barcode == 1) {
        this.$refs.barcode.focus();
      }
      this.itemStockable = true;
      this.itemExpired = false;
      this.items = [];
      this.$store.dispatch("modalWithMode", "");
      this.removeDataFromLocalStorage();
      this.getEditableData();
    },
    removeDataFromLocalStorage() {
      localStorage.removeItem("salesEditFormData");
      localStorage.removeItem("salesEditPaymentMethod");
    },
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("Customer created successfully.") >= 0) {
        this.dataSets.customers = this.customerLists;
      }
    },
    "formData.shipping_type"(value) {
      if(this.loadData == false){
        if (value) {
        this.formData.vatable_amount = 0;
        this.formData.vat_amount = 0;
        this.calculateVatableAmtExtraFieldCase();
        } else {
          this.calculateTotal();
          this.calculateVatableAmtExtraFieldCase();
        }
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
    this.removeDataFromLocalStorage();
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}

option.disabledColor {
  background: gray;
  color: white;
}
.form-control:disabled.expiry_date {
  color: red !important;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}
.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}
.input-group-text {
  outline: none;
}
input,
select,
textarea {
  background: #f4f7fa;
}
input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}
.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
</style>
